// test ////
export default [{
    path: '/admin',
    component: () =>
         import(/* webpackChunkName: "admin" */ '@@/core/pages/Index/Index'),
    meta: {
        title: 'پنل مدیریت'
    },
    children: [{
            path: '',
            component: () =>  import(/* webpackChunkName: "admin" */ '@@/core/pages/Dashboard/Dashboard'),
            meta: {
                title: 'پنل مدیریت'
            }
        },
        {
            path: '/admin/color',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/Color/Color'),
            meta: {
                title: 'رنگ '
            },
            props: {
                default: true,
                page: route => ({ search: route.query.q })
            }
        },
        {
            path: '/admin/size-chart',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/sizeChart/SizeChart/SizeChart'),
            meta: {
                title: 'سایز چارت'
            }
        },
        {
            path: '/admin/size-chart/edit/:id?',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/sizeChart/SizeChartCreateEdit/SizeChartCreateEdit'),
            meta: {
                title: 'ویرایش سایز چارت'
            },
            name: 'size-chart'
        },
        {
            path: '/admin/size-chart/create',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/sizeChart/SizeChartCreateEdit/SizeChartCreateEdit'),
            meta: {
                title: 'ثبت سایز چارت'
            },
        },
        {
            path: '/admin/size-chart-type',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/sizeChart/SizeChartType/SizeChartType'),
            meta: {
                title: 'انواع سایز چارت'
            },
        },
        {
            path: '/admin/provinces',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/area/Provinces/Provinces'),
            meta: {
                title: 'استان'
            }
        },
        {
            path: '/admin/cities',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/area/Cities/Cities'),
            meta: {
                title: 'شهر'
            }
        },
        {
            path: '/admin/brands',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/Brand/Brand'),
            meta: {
                title: 'برند'
            }
        },
        {
            path: '/admin/unit',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/Unit/Unit'),
            meta: {
                title: 'واحد'
            }
        },
        {
            path: '/admin/attributes',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/Attribute/Attribute'),
            meta: {
                title: 'ویژگی'
            }
        },
        {
            path: '/admin/specifications',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/specification/Specification/Specification'),
            meta: {
                title: 'مشخصات'
            }
        },
        {
            path: '/admin/specifications/create',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/specification/SpecificationCreateEdit/SpecificationCreateEdit'),
            meta: {
                title: 'ثبت مشخصات'
            }
        },
        {
            path: '/admin/specifications/edit/:id',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/specification/SpecificationCreateEdit/SpecificationCreateEdit'),
            meta: {
                title: 'ویرایش مشخصات'
            },
            name: 'specification.edit'
        },
        {
            path: '/admin/categories',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/category/Category/Category'),
            meta: {
                title: 'دسته بندی'
            },
        },
        {
            path: '/admin/categories/create',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/category/CategoryCreateEdit/CategoryCreateEdit'),
            meta: {
                title: 'ثبت دسته بندی'
            },
        },
        {
            path: '/admin/categories/edit/:id',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/category/CategoryCreateEdit/CategoryCreateEdit'),
            meta: {
                title: 'ویرایش دسته بندی'
            },
            name: 'category.edit'
        },
        {
            path: '/admin/posts',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/post/Post/Post'),
            meta: {
                title: 'پست'
            },
        },
        {
            path: '/admin/posts/create',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/post/PostCreateEdit/PostCreateEdit'),
            meta: {
                title: 'ثبت پست'
            },
        },
        {
            path: '/admin/posts/edit/:id',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/post/PostCreateEdit/PostCreateEdit'),
            meta: {
                title: 'ویرایش پست'
            },
            name: 'post.edit'
        },
        {
            path: '/admin/post-categories',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/post/PostCategories/PostCategories'),
            meta: {
                title: 'دسته بندی پست'
            },
        },
        {
            path: '/admin/post-comment/comment/:id',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/post/PostComments/PostComments'),
            meta: {
                title: 'نظرات پست'
            },
            name: 'show.comment'
        },
        {
            path: '/admin/post-comment/:post?',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/post/PostComments/PostComments'),
            meta: {
                title: 'نظرات پست'
            },
            name: 'post.comment'
        },
        {
            path: '/admin/settings/:group',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/Settings/Settings'),
            meta: {
                title: 'تنظیمات'
            },
        },
        {
            path: '/admin/slider',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/slider/SliderGroup/SliderGroup'),
            meta: {
                title: 'اسلایدر'
            },
        },
        {
            path: '/admin/slider/:group',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/slider/Slider/Slider'),
            meta: {
                title: 'اسلایدر'
            },
            name: 'slider'
        },
        {
            path: '/admin/menu',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/menu/MenuGroup/MenuGroup'),
            meta: {
                title: 'منو'
            },
        },
        {
            path: '/admin/menu/:group',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/menu/Menu/Menu'),
            meta: {
                title: 'منو'
            },
            name: 'menu'
        },
        {
            path: '/admin/coupon',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/Coupon/Coupon'),
            meta: {
                title: 'کد تخفیف'
            }
        },
        {
            path: '/admin/shipping',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/shipping/Shipping/Shipping'),
            meta: {
                title: 'حمل و نقل'
            }
        },
        {
            path: '/admin/shipping/:id/ranges',
            name: 'shipping.ranges',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/shipping/ShippingRanges'),
            meta: {
                title: 'بازه حمل و نقل'
            }
        },
        {
            path: '/admin/shipping/cities/:id',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/shipping/ShippingCities/ShippingCities'),
            meta: {
                title: 'حمل و نقل'
            },
            name: 'shipping.cities'
        },
        {
            path: '/admin/customers',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/customer/Customers/Customers'),
            meta: {
                title: 'مشتریان'
            }
        },
        {
            path: '/admin/customers/create',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/customer/CustomersCreateEdit/CustomersCreateEdit'),
            meta: {
                title: 'ثبت مشتری'
            }
        },
        {
            path: '/admin/customers/edit/:id',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/customer/CustomersCreateEdit/CustomersCreateEdit'),
            meta: {
                title: 'ویرایش مشتری'
            },
            name: 'customer.edit'
        },
        {
            path: '/admin/customers/show/:id',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/customer/CustomerShow/CustomerShow'),
            meta: {
                title: 'مشاهده مشتری'
            },
            name: 'customer.show'
        },
        {
            path: '/admin/admins',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/Admin/Admin'),
            meta: {
                title: 'مدیران'
            }
        },
        {
            path: '/admin/roles',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/Roles/Roles'),
            meta: {
                title: 'نقش ها'
            }
        },
        {
            path: '/admin/products',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/product/Products/Products'),
            meta: {
                title: 'لیست محصولات'
            }
        },
        {
            path: '/admin/products/create',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/product/ProductCreateEdit/ProductCreateEdit'),
            meta: {
                title: 'ایجاد محصول'
            }
        },
        {
            path: '/admin/store',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/Store/Store'),
            meta: {
                title: 'انبار'
            }
        },
        {
            path: '/admin/products/edit/:id',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/product/ProductCreateEdit/ProductCreateEdit'),
            meta: {
                title: 'ویرایش محصول'
            },
            props: true
        },
        {
            path: '/admin/comments',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/product/ProductComment/ProductComment'),
            meta: {
                title: 'نظرات محصول'
            },
            props: true
        },
        {
            path: '/admin/order',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/order/Orders/Orders'),
            meta: {
                title: 'سفارشات'
            }
        },
        {
            path: '/admin/order/create',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/order/OrderCreateEdit/OrderCreateEdit'),
            meta: {
                title: 'ثبت سفارش'
            },
            name: 'order.create'
        },
        {
            path: '/admin/order/:id',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/order/OrderDetail/OrderDetail'),
            meta: {
                title: 'جزئیات سفارش'
            },
            name: 'order.detail'
        },
        {
            path: '/admin/flash',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/flash/Flash/Flash'),
            meta: {
                title: 'کمپین فروش'
            }
        },
        {
            path: '/admin/flash/create',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/flash/FlashCreateEdit/FlashCreateEdit'),
            meta: {
                title: 'ثبت کمپین'
            }
        },
        {
            path: '/admin/flash/edit/:id',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/flash/FlashCreateEdit/FlashCreateEdit'),
            meta: {
                title: 'ویرایش کمپین'
            },
            name: 'flash.edit'
        },
        {
            path: '/admin/instagram',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/Instagram/Instagram'),
            meta: {
                title: 'اینستاگرام'
            },
        },
        {
            path: '/admin/faqs',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/Faq/Faq'),
            meta: {
                title: 'سوالات متداول'
            },
        },
        {
            path: '/admin/position',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/advertise/Position/Position'),
            meta: {
                title: 'جایگاه بنر'
            },
        },
        {
            path: '/admin/position/advertisements/:id',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/advertise/PositionAdvertisements/PositionAdvertisements'),
            meta: {
                title: 'جایگاه بنر'
            },
            name: 'position.advertisements'
        },
        {
            path: '/admin/advertise',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/advertise/Advertise/Advertise'),
            meta: {
                title: 'بنر'
            },
        },
        {
            path: '/admin/newsletters',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/newsletters/Newsletters/Newsletters'),
            meta: {
                title: 'خبرنامه'
            },
        },
        {
            path: '/admin/newsletters/create',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/newsletters/NewslettersCreate/NewslettersCreate'),
            meta: {
                title: 'ثبت خبرنامه'
            },
        },
        {
            path: '/admin/newsletters/:id',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/newsletters/NewslettersShow/NewslettersShow'),
            meta: {
                title: 'مشاهده خبرنامه'
            },
            name:'newsletters.show'
        },
        {
            path: '/admin/member-newsletters',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/newsletters/Member/Member'),
            meta: {
                title: 'اعضا خبرنامه'
            },
        },
        {
            path: '/admin/pages',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/page/Pages/Pages'),
            meta: {
                title: 'لیست صفحات'
            },
        },
        {
            path: '/admin/pages/create',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/page/PageCreateEdit/PageCreateEdit'),
            meta: {
                title: 'ایجاد صفحه جدید'
            },
        },
        {
            path: '/admin/pages/:id/edit',
            name: 'page.edit',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/page/PageCreateEdit/PageCreateEdit'),
            meta: {
                title: 'ویرایش صفحه'
            },
        },
        {
            path: '/admin/contact',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/Contact/Contact'),
            meta: {
                title: 'تماس با ما'
            },
        },
        {
            path: '/admin/sets',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/sets/Set/Set'),
            meta: {
                title: 'ست ها'
            },
        },
        {
            path: '/admin/sets/create',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/sets/SetCreateEdit/SetCreateEdit'),
            meta: {
                title: 'ثبت ست'
            },
        },
        {
            path: '/admin/sets/edit/:id',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/sets/SetCreateEdit/SetCreateEdit'),
            meta: {
                title: 'ویرایش ست'
            },
            name: 'set.edit'
        },
        {
            path: '/admin/recommendation-groups',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/recommendations/RecommendationGroups/RecommendationGroups'),
            meta: {
                title: 'محصولات پیشنهادی'
            }
        },
        {
            name: 'recommendation',
            props: true,
            path: '/admin/recommendation/:group',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/recommendations/Recommendation/Recommendation'),
            meta: {
                title: 'مرتب سازی لیست محصولات'
            }
        },
        {
            name: 'gift',
            props: true,
            path: '/admin/gifts',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/gift/Gift/Gift'),
            meta: {
                title: 'هدایا'
            }
        },
        {
            path: '/admin/reports/visit',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/report/Visit/Visit'),
            meta: {
                title: 'گزارش بازدیدها'
            }
        },
        {
            path: '/admin/reports/visit/:date',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/report/Visit/VisitDetail'),
            meta: {
                title: 'جزئیات بازدید'
            }
        },
        {
            path: '/admin/reports/customers',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/report/CustomersReport/CustomersReport'),
            meta: {
                title: 'گزارش کاربران'
            }
        },
        {
            path: '/admin/reports/products',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/report/ProductsReport/ProductsReport'),
            meta: {
                title: 'گزارش محصولات'
            }
        },
        {
            path: '/admin/reports/varieties',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/report/VarietiesReport/VarietiesReport'),
            meta: {
                title: 'گزارش تنوع ها'
            }
        },
        {
            path: '/admin/reports/wallets',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/report/WalletReport/WalletReport'),
            meta: {
                title: 'گزارش کیف پول'
            }
        },
        {
            path: '/admin/reports/orders',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/report/OrdersReport/OrdersReport'),
            meta: {
                title: 'گزارش سفارشات(تکی)'
            }
        },
        {
            path: '/admin/shop',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/physicalShop/Shop'),
            meta: {
                title: 'فروشگاه حضوری'
            },
        },
        {
            path: '/admin/shop/orders',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/physicalShop/ShopIndex'),
            meta: {
                title: 'لیست فروش حضوری'
            },
        },
        {
            path: '/admin/shop/orders/:id',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/physicalShop/ShopShow'),
            meta: {
                title: 'مشاهده سفارش حضوری'
            },
        },
        {
            path: '/admin/shop/create',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/physicalShop/ShopCreate'),
            meta: {
                title: 'ثبت خرید'
            },
        },
        {
            path: '/admin/customer_roles',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/customerRole/CustomerRole'),
            meta: {
                title: 'نقش مشتریان'
            },
        },
        {
            path: '/admin/sellers',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/seller/Seller/Seller'),
            meta: {
                title: 'فروشندگان'
            },
        },
        {
            path: '/admin/transactions',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/Wallet/Wallet'),
            meta: {
                title: 'تراکنش های کیف پول'
            },
        },
        {
            path: '/admin/withdraws',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/withdraw/Withdraw'),
            meta: {
                title: 'برداشت های کیف پول'
            },
        },
        {
            path: '/admin/htaccess',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/Htaccess/Htaccess'),
            meta: {
                title: 'htaccess'
            },
        },
        {
            path: '/admin/shipping_excels',
            component: () =>
                import(/* webpackChunkName: "admin" */ '@@/core/pages/shippingExcels/ShippingExcels'),
            meta: {
                title: 'اکسل پست'
            },
        },
        {
            path: '/admin/newsletters-offers',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/newsletters/offers/offers'),
            meta: {
                title: 'اعضای پیشنهاد شگفت انگیز'
            },
        },
        {
            path: '/admin/hidden-offers',
            component: () =>
                 import(/* webpackChunkName: "admin" */ '@@/core/pages/hiddenOffer/hiddenOffer'),
            meta: {
                title: ' پیشنهاد های مخفی'
            },
        },
    ]
},
{
    path: '/admin/login',
    component: () =>
         import(/* webpackChunkName: "admin" */ '@@/core/pages/auth/Login/Login'),
    meta: {
        title: 'ورود به پنل مدیریت'
    },
   
},
{
    path: '/admin/print-orders',
    component: () =>  import(/* webpackChunkName: "admin" */ '@@/core/pages/order/OrderPrint/OrderPrint'),
    meta: {
        title: 'چاپ فاکتور ها'
    },
    name: 'orders.print'
}
]
