import Vuex from "vuex";
import Vue from "vue";
import adminModule from '@@/core/store'
import frontModule from '@/parts/Front/store'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        admin: adminModule,
        front: frontModule
    },
})

export default store;
